<template>
  <CSidebar
    aside
    :show="$store.state.courierInvoiceAdjustmentsAsideShow"
    @update:show="(val) => $store.commit('set', ['courierInvoiceAdjustmentsAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'courierInvoiceAdjustmentsAsideShow')" />

    <!-- Error Alert -->
    <CListGroup class="list-group-accent mt-5" v-show='showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase">
        Error
      </CListGroupItem>

      <CListGroupItem href="#" class="list-group-item-accent-danger list-group-item-divider">
        <div class="c-avatar float-left mr-2">
          <CIcon name="cil-bug" size="xl" class="c-avatar-img text-danger"/>
        </div>
        <div>{{ errorAlertMessage }}</div>
      </CListGroupItem>
    </CListGroup>
    <!-- / Error Alert -->

    <CListGroup class="scrollable list-group-accent mt-5" v-if='!showErrorAlert && $store.state.courierInvoiceAdjustmentsAsideShow'>
      <template>
        <CListGroupItem class="mb-0 list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
          ADD ADJUSTMENT
        </CListGroupItem>
        <CListGroupItem class="list-group-item-accent-success" v-if="editable">
          <CInput
            class="mb-1"
            type="number"
            label="Amount:"
            placeholder="Adjustment amount"
            step="0.01"
            pattern="^\d+(?:\.\d{1,2})?$"
            onblur="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
            :value.sync="form.amount"
          >
            <template #prepend-content><CIcon name="cil-euro" /></template>
          </CInput>

          <CTextarea class="mb-1" rows="2" placeholder="Note" :value.sync="form.description" />

          <CButton type="submit" color="success" size="sm" shape="pill" @click="addAdjustment">
            <CIcon name="cil-plus" /> Add
          </CButton>
        </CListGroupItem>
        <CListGroupItem class="list-group-item-accent-danger list-group-item-divider" v-else>
          <div class="vertical-center flex-gap-2 text-danger">
            <CIcon name="cil-warning" size="lg" />
            This invoice is not editable.
          </div>
          <ul class="mt-2 pl-4">
            <li v-if="invoice.is_paid">The invoice has been paid.</li>
            <li v-else>The invoice has not been paid yet.</li>
            <li v-if="invoice.transfered">The invoice has been transferred.</li>
            <li v-else>The invoice has not been transferred yet.</li>
          </ul>
        </CListGroupItem>
      </template>

      <CListGroupItem class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        Invoice Adjustments
      </CListGroupItem>

      <template v-for="adj in invoice?.item_details?.adjustments">
        <CListGroupItem class="list-group-item-divider list-group-item-accent-dark">
          <div class="d-flex">
            {{ adj.amount | toCurrency }}
            <div class="ml-auto small text-muted">
              {{ moment(adj.date).format('DD/MM/YYYY') }}
            </div>
          </div>
          <div class="small text-muted">
            {{ adj.description }}
          </div>
        </CListGroupItem>
      </template>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading"/>

  </CSidebar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdjustmentsAside",
  props: {
    invoice: {
      type: Object,
    },
  },
  components: {
  },
  data() {
    return {
      loading: false,

      errorAlertMessage: '',
      showErrorAlert: false,
      form: null
    };
  },
  watch: {
    reloadParams() {
      this.form = {
        amount: 0,
        description: null
      }
    },
  },
  computed: {
    ...mapGetters(["errors"]),
    reloadParams() {
      return [this.$store.state.courierInvoiceAdjustmentsAsideShow];
    },
    editable() {
      return this.invoice && !(this.invoice.is_paid || this.invoice.transfered);
    }
  },

  methods: {
    addAdjustment() {
      this.loading = true;

      this.$axios
        .post(this.$backend.COURIER.INVOICES.ADD_ADJUSTMENT.replace("{id}", this.invoice.id), this.form)
        .then(({data}) => {
          this.$toast.success(data.data.message);
          this.$store.commit('set', ['courierInvoiceAdjustmentsAsideShow', false]);
          this.invoice.isAdjustmentAdded = true;
        })
        .catch((error) => {
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
          console.log(Object.values(this.errors).join());

        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}
</style>
