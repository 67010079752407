<template>
  <CCardBody>

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="d-flex flex-md-row flex-column" style="gap: 0.5rem;">
            <!-- Invoice Type Filter -->
            <div class="flex-grow-1">
              <v-select
                class="v-select-filter"
                placeholder="Select invoice type.."
                v-model="selectedInvoiceFilter"
                :reduce="o => o.value"
                :options="[
                  { value: 'all', label: 'All'},
                  { value: 'paid', label: 'Paid'},
                  { value: 'unpaid', label: 'Unpaid'},
                  { value: 'mailed', label: 'Mailed'},
                  { value: 'not-mailed', label: 'Not Mailed'},
                  { value: 'transfered', label: 'Transfered'},
                  { value: 'not-transfered', label: 'Not Transfered'},
                ]"
              />
            </div>

            <!-- Courier Filter -->
            <div class="flex-grow-1">
              <v-select
                class="v-select-filter"
                placeholder="Select courier.."
                v-model="selectedCourier"
                label="first_name"
                :options="allCouriers"
                :loading="loading && allCouriers.length <= 0"
                @input="getAllItems"
              >
                <template v-slot:option="c">
                  <span>{{ `#${c.id} - ${c.first_name}` }}</span>
                </template>
              </v-select>
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <div v-if="!loading">
      <CDataTable
        striped
        hover
        :items="loadedItems"
        :fields="fields"
        :sorter="{ external: true, resetable: true }"
        :table-filter="{ external: true, lazy: true, placeholder: '...' }"
        :tableFilterValue.sync="search"
        :column-filter="{ external: true, lazy: true }"
        :itemsPerPageSelect="{
          external: true,
          values: [5, 15, 25, 50, 100, 150, 200, 250, 500],
        }"
        :items-per-page.sync="itemsPerPage"
        :loading="loading"
        :noItemsView="{
          noResults: 'No filtering results are available!',
          noItems: 'No invoices found!',
        }"
        @update:sorter-value="sorterValue"
        @update:table-filter-value="tableSearch"
        @pagination-change="paginationChange"
        @row-clicked="rowClicked"
      >
        <!-- Check Box -->
        <template #select="{item}">
          <td class="text-right">
            <CInputCheckbox
              v-if="isSelectable(item)"
              class="ml-1"
              :checked="item._selected"
              @update:checked="() => select(item)"
              custom
            />
          </td>
        </template>

        <!-- ID -->
        <template #id="{item}">
          <td>
            #{{ item.id }}
          </td>
        </template>

        <!-- Mailed  -->
        <template #mailed="{item}">
          <td class="text-center">
            <CIcon
              :height="32"
              :class="`text-${item.mailed ? 'success' : 'danger'}`"
              :name="`cil-${item.mailed ? 'check-alt' : 'x'}`"
            />
          </td>
        </template>

        <!-- Net amount -->
        <template #net_amount="{item}">
          <td>
            {{ item.net_amount | toCurrency('remove_thousand_separator') }}
          </td>
        </template>

        <!-- Balance -->
        <template #balance="{item}">
          <td>
            <strong :class="`text-${item.is_paid == true ? 'success' : 'danger'}`">
              {{ (item.total_payment - item.net_amount) | toCurrency('signDisplay') }}
            </strong>
          </td>
        </template>

        <!-- Invoice Number -->
        <template #invoice_number="{item}">
          <td>
            <CLink @click="viewInvoice(item)">
              {{ item.invoice_number }}
            </CLink>
          </td>
        </template>

        <!-- Invoice Type -->
        <template #invoice_type="{item}">
          <td>
            {{ invoiceTypeMap[item.invoice_type] || 'Invoice' }}
          </td>
        </template>

        <!-- Invoice Date Filter -->
        <template #invoice_date-filter>
          <div class="input-group mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text p-1 w-min">From</span>
            </div>
            <input id="date-filter-from" type="date" v-model="filters.invoices.startDate" class="w-min" />
          </div>

          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text p-1 w-min">To</span>
            </div>
            <input id="date-filter-from" type="date" v-model="filters.invoices.endDate" class="w-min" />
          </div>
        </template>

        <!-- Invoice Date -->
        <template #invoice_date="{item}">
          <td :class="isToday(item.invoice_date) ? 'text-danger font-weight-bold' : ''">
            {{ item.invoice_date }}
          </td>
        </template>

        <!-- Courier -->
        <template #supplier="{ item }">
          <td>
            #{{ item.supplier?.id }}
            <CLink class="font-weight-bold" target="_blank" :to="{ name: 'Edit Courier', params: { id: item.supplier?.id } }">
              {{ item.supplier?.first_name }}
            </CLink>
          </td>
        </template>

        <!-- Bank Transaction -->
        <template #txn="{item}">
          <td>
            <CButton v-if="item.bank_transaction"
              size="sm"
              color="link"
              v-c-tooltip="{ content: `Download TXN (#${item.txn_id})` }"
              @click="downloadTxnFile(item.bank_transaction.file_name)"
            >
              <CIcon name="cil-transfer" />
            </CButton>
          </td>
        </template>

        <!-- Actions -->
        <template #actions="{ item }">
          <td>
            <div class="vertical-center flex-gap-2 justify-content-center">
              <CButton size="sm" :color="item.is_paid ? 'success' : 'warning'" shape="pill" v-c-tooltip="{ html: true, content: 'Payments' }" @click="showPayments(item)">
                <CIcon name="cil-euro" />
              </CButton>

              <CDropdown>
                <template #toggler>
                  <CButton color="ghost" size="sm" class="p-0">
                    <CIcon name="cil-options" />
                  </CButton>
                </template>
                <CDropdownItem @click="viewInvoice(item)">
                  <CIcon name="cil-cloud-download" class="mr-2" />
                  Download
                </CDropdownItem>
                <CDropdownItem @click="sendInvoice(item)">
                  <CIcon name="cil-send" class="mr-2" />
                  Send E-Mail
                </CDropdownItem>
                <CDropdownItem @click="showAdjustments(item)">
                  <CIcon name="cil-pencil" class="mr-2" />
                  Adjustments
                </CDropdownItem>
              </CDropdown>
            </div>
          </td>
        </template>
      </CDataTable>
    </div>
    <div v-else class="mb-4">
      <CSpinner color="primary" />
    </div>

    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <!-- Mark as Paid -->
          <div v-if="txnId && selectedInvoiceFilter == 'unpaid'" class="action d-flex flex-gap-2">
            <CLink class="mb-1" @click="selectAllItems"><CIcon name="cil-library-add" /></CLink>
            <CLink @click="deselectItems"><CIcon name="cil-library" /></CLink>
            <CButton v-if="selectedItems.length" size="sm" color="info" shape="pill" @click="markAllAsPaid">
              Mark as Paid
            </CButton>
          </div>

          <div>
            <h5 v-if="selectedItems.length" class="mt-1">Selected: <span class="d-inline count bg-primary pb-1">{{ selectedItems.length }}/{{ total }}</span></h5>
            <h5 v-else class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div class="action">
            <CButton size="sm" color="success" @click="createInvoicesModal = true">
              <CIcon name="cil-send" /> Create Invoices
            </CButton>
          </div>

          <div class="action">
            <CButton size="sm" color="primary" v-c-tooltip="{ html: true, content: `Download All Filtered Invoices` }" @click="downloadZip" :disabled="total == 0">
              <CIcon name="cil-cloud-download" /> Download All
            </CButton>
          </div>

          <div class="action">
            <CButton size="sm" color="info" variant="ghost" @click="transferInvoice">
              <CIcon name="cil-data-transfer-up" /> Transfer All Untransferred Invoices
            </CButton>
          </div>

          <div v-if="total && selectedInvoiceFilter == 'not-mailed'">
            <CButton size="sm" color="info" variant="ghost" @click="sendAllUnmailedInvoices">
              <CIcon name="cil-send" /> Send All Unmailed Invoices by Email
            </CButton>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination align="center" :dots='false' :pages="pages" :active-page.sync="activePage" />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <!-- Create invoices modal -->
    <CModal class="create-invoices" title="Create Invoices" size="sm" color="white" :show.sync="createInvoicesModal">
      <v-date-picker
        v-model="selectedDate"
        mode="date"
        title-position="left"
        is-inline
        is-expanded
        :first-day-of-week="1"
        :disabled-dates='{ weekdays: [1,2,3,4,5,6] }'
        is-required
      />
      <template #footer class="p-1">
        <CButton @click="createInvoicesModal = false" color="link">Cancel</CButton>
        <CButton @click="createInvoices" :disabled="selectedDate === null" color="info" shape="pill">
          <CIcon name="cil-send" /> Create invoices
        </CButton>
      </template>
    </CModal>

    <!-- SEPA file created modal -->
    <CModal
      class="modal-txn"
      title="SEPA file created."
      size="sm"
      :show.sync="txnModal"
      :closeOnBackdrop="false"
      centered
    >
      <template #footer class="p-1">
        <CButton :to="{ name: 'Bank Transactions' }" color="link">View All</CButton>
        <CButton @click="downloadTxnFile(txnFileName)" size="sm" color="primary"><CIcon name="cil-cloud-download" /> Download</CButton>
      </template>
    </CModal>

    <payments-aside :invoice.sync="selectedInvoice" />
    <adjustments-aside :invoice.sync="selectedInvoice" />

    <mc-spinner :opacity="0.8" v-show="submitted" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>

<script>
import { mapState } from 'vuex'
import { DatePicker } from 'v-calendar'
import _debounce from 'lodash/debounce';
import PaymentsAside from "./Payments.vue";
import AdjustmentsAside from "./Adjustments.vue";

export default {
  name: "BackendTable",
  components: {
    'v-date-picker': DatePicker,
    'payments-aside': PaymentsAside,
    'adjustments-aside': AdjustmentsAside,
  },
  data() {
    return {
      loadedItems: [],
      selectedInvoice: null,
      fields: [
        { key: "actions", filter: false, sorter: false, _style: "min-width: 84px; width: 84px; text-align: center;" },
        { key: "id", filter: false, _style: "min-width: 50px; width: 50px;" },
        { key: "mailed", label: "Sent", filter: false, _style: "min-width: 50px; width: 50px; text-align: center;" },
        { key: "invoice_number", label: "Inv.Number", filter: false, _style: "min-width: 100px; width: 120px;" },
        { key: "invoice_type", label: "Inv.Type", filter: false, _style: "min-width: 100px; width: 120px;" },
        { key: "net_amount", label: "Net Amount", filter: false, _style: "min-width: 100px; width: 120px;" },
        { key: "balance", filter: false, _style: "min-width: 100px; width: 120px;" },
        { key: "invoice_date", label: "Date", filter: true, _style: "min-width: 160px; width: 160px;" },
        { key: "supplier", label: "Courier", filter: false, _style: "width: 200px;", sorter: false },
        { key: "txn", label: "TXN", filter: false, _style: "min-width: 50px; width: 50px;", sorter: false },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      direction: null,
      search: null,
      filteredColumn: null,

      allCouriers: [],
      selectedCourier: null,
      selectedInvoiceFilter: null,

      loading: false,
      submitted: false,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,

      // Make Invoices
      createInvoicesModal: false,
      selectedDate: null,

      // TXN
      txnModal: false,
      txnFileName: null,
      selectedItems: [],
    };
  },

  async beforeMount() {
    this.filterByDate = _debounce(async () => {
      await this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage = this.queryPage;
    this.selectedInvoiceFilter = this.txnId ? 'unpaid' : 'not-mailed';

    await this.getCouriers();

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage) {
        this.$router.push({ name: this.$route.name, query: { page: this.activePage } });
      }
      this.onTableChange();
    },
    dateFilter() {
      this.filterByDate();
    },
    selectedInvoiceFilter: async function (newVal, oldVal) {
      if (newVal == "unpaid") {
        // Add select field
        this.txnId && this.fields.splice(0, 0, { key: "select", label: "", sorter: false, filter: false, _style: "width: 1%" });
      } else if (oldVal == "unpaid") {
        // Remove select field
        this.txnId && this.fields.splice(0, 1);
      }

      this.getAllItems();
    },
    selectedInvoice: {
      handler: function (val) {
        if (val.isPaymentAdded || val.isAdjustmentAdded) {
          this.getAllItems();
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapState(['filters']),
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage] },
    queryPage() { return parseInt(this.$route.query.page) || 1 },
    dateFilter() { return [this.filters.invoices.startDate, this.filters.invoices.endDate] },
    txnId() { return this.$route.params.txn_id },
    invoiceTypeMap() {
      return {
        self_billing: 'SelfBilling',
        payment_statement: 'PaymentStatement'
      };
    }
  },

  methods: {
    async getAllItems(loading = true) {
      this.loading = loading;

      var url = new URL(this.$backend.COURIER.INVOICES.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          orderBy: this.orderBy,
          direction: this.direction,

          search: this.search,
          invoiceFilter: this.selectedInvoiceFilter,
          supplier: this.selectedCourier?.id,
          date_from: this.filters.invoices.startDate,
          date_to: this.filters.invoices.endDate,
          txn_id: this.txnId
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data.map((el) => {
              return { ...el, _classes: '', updated: false };
            });

            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getCouriers() {
      this.loading = true;
      await this.$axios
        .get(this.$backend.COURIER.COURIERS.BASIC_LIST, {
          params: {
            'exclude-subordinates': true,
          },
        })
        .then(({ data }) => this.allCouriers = data)
        .catch(() => this.allCouriers = [])
        .finally(() => this.loading = false);
    },

    async createInvoices() {
      this.createInvoicesModal = false;
      this.submitted = true;

      await this.$axios
        .post(this.$backend.COURIER.INVOICES.CREATE, {
          'end-date': new Date(this.selectedDate).toLocaleDateString("BE").split(".").reverse().join("-")
        })
        .then(({ data }) => {
          if (this.txnFileName = data.sepa_file) {
            this.txnModal = true;
          }
          this.getAllItems();
          this.$toast.success(data.message);
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        })
        .finally(() => {
          window.scrollTo(0, 0);
          this.submitted = false;
          this.selectedDate = null;
        });
    },

    async viewInvoice(item) {
      this.submitted = true;

      await this.$axios
        .get(this.$backend.COURIER.INVOICES.VIEW.replace("{id}", item.id), { responseType: 'blob' })
        .then((response) => {
          const invoiceType = this.invoiceTypeMap[item.invoice_type] || 'Invoice';
          const fileName = `Courier_${invoiceType}_${item.invoice_number}.pdf`;

          // Download file from Blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
          // <a href="blob:http://localhost:8080/f11c5655-6941-40b3-97bd-18152eb2a13b" download="Factuur_2022_15.pdf"></a>
        })
        .finally(() => this.submitted = false);
    },

    async downloadZip() {
      if (this.selectedCourier === null) {
        this.$toast.warning('Please select a courier to download all invoices!');
        return;
      }

      if (!this.filters.invoices.startDate) {
        this.$toast.warning('Please select a start date to download all invoices!');
        return;
      }

      this.submitted = true;

      var url = new URL(this.$backend.COURIER.INVOICES.DOWNLOAD_ALL),
        params = {
          search: this.search,
          invoiceFilter: this.selectedInvoiceFilter,
          supplier: this.selectedCourier?.id,
          date_from: this.filters.invoices.startDate,
          date_to: this.filters.invoices.endDate,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios
        .get(url, { responseType: 'blob' })
        .then(({ data }) => {
          const fileName = `Courier_(#${this.selectedCourier?.id})_Invoices.zip`;

          // Download file from Blob
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .finally(() =>this.submitted = false);
    },

    async sendInvoice(item) {
      this.submitted = true;

      await this.$axios
        .post(this.$backend.COURIER.INVOICES.SEND.replace("{id}", item.id))
        .then(({ data }) => {
          item.mailed = true;
          this.$toast.success(data.data.message);
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        })
        .finally(() => {
          window.scrollTo(0, 0);
          this.submitted = false;
        });
    },

    async sendAllUnmailedInvoices() {
      this.submitted = true;

      await this.$axios
        .post(this.$backend.COURIER.INVOICES.SEND_ALL_UNMAILED)
        .then(({ data }) => {
          this.$toast.success(data.data.message);
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        })
        .finally(() => {
          window.scrollTo(0, 0);
          this.submitted = false;
        });
    },

    async transferInvoice() {
      this.submitted = true;

      await this.$axios
        .post(this.$backend.COURIER.INVOICES.TRANSFER)
        .then(({ data }) => {
          this.getAllItems();
          this.$toast.success(data.data.message);
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        })
        .finally(() => {
          window.scrollTo(0, 0);
          this.submitted = false;
        });
    },

    async downloadTxnFile(fileName) {
      this.submitted = true;
      this.txnModal = false;

      await this.$axios
        .get(this.$backend.BANK_TRANSACTIONS.DOWNLOAD.replace("{file_name}", fileName), { responseType: 'blob' })
        .then((response) => {
          // Download file from Blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();

          this.$toast.success(`SEPA file downloaded.`);
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    async markAllAsPaid() {
      const confirm = await this.mSwal.fire({
        text: "Are you sure you want to continue?",
        title: "The selected invoices will be marked as paid?",
        icon: "warning",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel"
      });

      if (confirm.isDismissed) return;

      try {
        this.selectedItems.forEach(invoice => {
          this.$set(invoice, 'updated', true);
        });

        const transformedInvoices = this.selectedItems.map(invoice => ({
          supplier_id: invoice.supplier_id,
          invoice_id: invoice.id,
          amount: Math.abs(invoice.total_payment - invoice.net_amount).toFixed(2),
          type: '1', // 0: Received, 1: Paid
          note: 'Purchase Inv.Number: ' + invoice.invoice_number
        }));

        await this.$axios.post(this.$backend.COURIER.PAYMENTS.STORE_MULTIPLE, transformedInvoices);
      } catch (error) {
        if (error.response) {
          this.$toast.error(`${error.response.data.message}`);
        } else if (error.request) {
          this.$toast.error('No response received from the server.');
        } else {
          this.$toast.error('An unexpected error occurred.');
        }
      } finally {
        this.deselectItems();
        await this.getAllItems(false);
      }
    },

    // Table events
    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.deselectItems();
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.onTableChange();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.onTableChange();
    },
    tableSearch(search) {
      this.onTableChange();
    },
    rowClicked(item, index, column, e) {
      const checkTarget = !['INPUT', 'LABEL', 'BUTTON', 'svg', 'path', 'A', 'IMG', 'SPAN'].includes(e.target.tagName);
      if (checkTarget && this.isSelectable(item)) {
        this.select(item)
      }
    },

    // Select invoice(s)
    isSelectable(item) {
      return item.is_paid == false
        && this.txnId && this.selectedInvoiceFilter == 'unpaid';
    },
    select(item) {
      this.$set(item, "_selected", !item._selected);

      if (item._selected) {
        item._classes = item._classes.concat(" table-selected");
        this.selectedItems.push(item);
      } else {
        item._classes = item._classes.replace(" table-selected", "");
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
      }
    },
    deselect(item) {
      this.$set(item, "_selected", false);
      item._classes = item._classes.replace(" table-selected", "");
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
    },
    selectAllItems() {
      this.deselectItems();

      this.loadedItems
        .filter(this.isSelectable)
        .forEach(item => {
          this.$set(item, "_selected", true);
          item._classes = item._classes.concat(" table-selected");
          this.selectedItems.push(item);
        });
    },
    deselectItems() {
      this.loadedItems
        .filter(this.isSelectable)
        .forEach(this.deselect);
    },

    // Filters
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.selectedCourier = null;
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },

    // Show invoice payments
    showPayments(invoice) {
      this.selectedInvoice = {
        ...invoice,
        isPaymentAdded: false
      };
      this.$store.commit("toggle", "courierInvoicePaymentsAsideShow");
    },

    // Show invoice adjustments
    showAdjustments(invoice) {
      this.selectedInvoice = {
        ...invoice,
        isAdjustmentAdded: false
      };
      this.$store.commit("toggle", "courierInvoiceAdjustmentsAsideShow");
    },

    // Helper functions
    isToday(dateString) {
      const someDate = new Date(dateString);
      const today = new Date()
      return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
    }
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}
@media (min-width: 576px) {
  input[aria-label='table filter input'] {
    width: 280px !important;
  }
}

.create-invoices .modal-header {
  padding: 0.75rem !important;
}
.create-invoices .modal-footer {
  padding: 0.25rem !important;
}
.create-invoices .vc-container {
  box-shadow: none !important;
  border: none !important;
}

.is-paid .c-switch-input:disabled ~ .c-switch-slider {
  opacity: 1;
}

/* Modal TXN */
.modal-txn .modal-body {
  display: none;
}
</style>

<style scoped>
/* Date Filter */
span.w-min {
  width: 48px;
}
input.w-min {
  width: 100px;
}
input[type="date" i],
input[type="datetime-local" i] {
  color: #f9b115;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  padding-left: 4px;
  margin: 0;
}

.actions .action {
  border-right: 1px solid #d8dbe0 !important;
  padding-right: 0.75rem !important;
}
</style>
