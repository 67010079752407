<template>
  <CRow>
    <CCol sm="12">
      <CCard>

        <CCardHeader>
          <CIcon name="cil-spreadsheet" />
          <h5 class="d-inline ml-2">{{ routeTitle }}</h5>
          <div class="card-header-actions">
            <CButton v-if="txnId" color="dark" :to="{ name: 'Bank Transactions' }">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back to Bank Transactions
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable";

export default {
  name: "CourierInvoices",
  components: {
    BackendTable,
  },
  computed: {
    txnId() { return this.$route.params.txn_id },
    routeTitle() { return this.$route.meta.label || this.$route.name },
  },
};
</script>
